import Link from "next/link";

const SidebarMenuItem = ({
  children,
  handler,
  number,
  active,
  link,
  chat = false,
  type = "button",
}) => {
  if (type === "button") {
    return (
      <button
        className={`js-sidebarmenu flex items-center justify-start w-full gap-3 px-6 sm:px-3 py-5 sm:py-2 sm:rounded-md  cursor-pointer  ${
          active
            ? "bg-gray-100 text-neutral-800"
            : "hover:bg-gray-100 text-neutral-500 hover:text-neutral-800"
        } ${chat ? "!text-primaryLight sm:hidden" : ""}`}
        onClick={() => {
          handler(number);
        }}
      >
        {children}
      </button>
    );
  } else if (type === "link") {
    return (
      <Link
        href={link}
        className={`js-sidebarmenu flex items-center justify-start w-full gap-3 px-6 sm:px-3 py-5 sm:py-2 sm:rounded-md  cursor-pointer  ${
          active
            ? "bg-gray-100 text-neutral-800"
            : "hover:bg-gray-100 text-neutral-500 hover:text-neutral-800"
        } ${chat ? "!text-primaryLight sm:hidden" : ""}`}
      >
        {children}
      </Link>
    );
  }
};

export default SidebarMenuItem;
